import ScrollAnimation from '../../../utils/scrollAnimation';

const OffHoursMessage = ({
  lang,
  disabled,
}: {
  lang: Locale.Keys;
  disabled: boolean;
}) => {
  return (
    <>
      {!disabled && (
        <ScrollAnimation animateIn='fadeIn' duration={500}>
          <div className='offhours'>
            <h4>{lang.coraCaffe.offHoursMessage.header}</h4>
            <p>{lang.coraCaffe.offHoursMessage.p1}</p>
            <p>{lang.coraCaffe.offHoursMessage.p2}</p>
            <p>{lang.coraCaffe.offHoursMessage.footer}</p>
          </div>
        </ScrollAnimation>
      )}
    </>
  );
};

export default OffHoursMessage;
