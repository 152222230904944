import { useEffect } from 'react';
import heroImg from '../../assets/imgs/home/gallery/16.jpg';
import Constants from '../../utils/Constants';
import { CapitalizeFirstLeter } from '../../utils/CapitalizeFirstLetter';

const Impressum = ({ lang }: { lang: Locale.Keys }) => {
  useEffect(() => {
    document.title = `${CapitalizeFirstLeter(
      Constants.PROPERTY_NAME
    )} - ${CapitalizeFirstLeter(Constants.IMPRESSUM)}`;
  }, []);

  return (
    <div id={Constants.IMPRESSUM} className='impressum'>
      <div className='impressum_hero'>
        <img src={heroImg} alt='impressum hero' />
      </div>
      <div className='impressum_container container'>
        <h1>{Constants.IMPRESSUM}</h1>
        <h3>{lang.impressum.propName}</h3>
        <p>Cora Hotel</p>
        <h3>{lang.impressum.propAddress}</h3>
        <p>
          Put Supavla 39
          <br />
          HR-21000 SPLIT
          <br />
          {lang.impressum.country}
        </p>
        <h3>{lang.impressum.compName}</h3>
        <p>MANAS d.o.o. (LLC)</p>
        <h3>{lang.impressum.compAddress}</h3>
        <p>
          Barakovićeva 18
          <br />
          HR-21000 SPLIT
          <br />
          {lang.impressum.country}
        </p>
        <h3>{lang.impressum.vat}</h3>
        <p>HR77290534017</p>
        <h3>{lang.impressum.commercialCourt.title}</h3>
        <p>{lang.impressum.commercialCourt.p1}</p>
        <h3>{lang.impressum.phone}</h3>
        <p>
          <a href='tel:+385 21 293 100' className='opacity_transition_low'>
            +385 21 293 100
          </a>
          <br />
          <a href='tel:+385 91 293 1005' className='opacity_transition_low'>
            +385 91 293 1005
          </a>
        </p>
        <h3>Email</h3>
        <p>
          <a
            href='mailto:reservations@cora-hotel.com'
            className='opacity_transition_low'
          >
            reservations@cora-hotel.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default Impressum;
