export const en: Locale.Keys = {
  menu: {
    ourStory: 'OUR STORY',
    roomsSuites: 'ROOMS & SUITES',
    stayingCora: 'STAYING AT CORA',
    coraCaffe: 'CORA CAFFE',
    spa: 'WELLNESS & SPA',
    meetingsEvents: 'MEETINGS & EVENTS - Busy Cora',
    offers: 'SPECIAL OFFERS',
    gallery: 'GALLERY',
    contactUs: 'CONTACT US',
    bookNow: 'BOOK NOW',
  },
  about: {
    title: 'A warm welcome, from the moment you arrive',
    p1: 'Cora, an intimate hotel with 34 rooms, is a sophisticated combination of comfort and urban dynamism. Settled in a quiet neighborhood yet minutes of driving from the city center, Cora is close to everything you want to see and experience in the ancient city of Split.',
    p2: 'Originating in 1973, the hotel building has gone through thorough property modernization and offers an impressively sleek, warm and welcoming atmosphere. With the aim of creating a bespoke residential feel that takes the guest experience to the next level, personalized service and guest satisfaction are top priorities for our staff.',
  },
  services: {
    title: 'FACILITIES AND SERVICE',
    frontDesk: '24/7 FRONT DESK',
    breakfast: 'SUMPTOUS BREAKFAST',
    rooftop: 'ROOFTOP BAR',
    spa: 'WELLNESS & SPA',
    gym: 'GYM',
    conference: 'CONFERENCE VENUE',
    wheelchair: 'WHEELCHAIR ACCESS',
    pet: 'PET FRIENDLY',
    parking: 'PARKING',
    eCharging: 'E-CHARGING',
  },
  roomsAndSuites: {
    title: 'ROOMS AND SUITES',
    subtitle: 'YOUR PRIVATE PLACE OF LUXURY',
    p1: 'Each room and suite offers a refined, residentially styled atmosphere completed with the latest technology, comfortable furnishings and exquisite attention to detail.',
    comfortTitle: 'Comfort',
    comfort: 'Comfort Room',
    superiorCourtyardTitle: 'Superiror I',
    superiorCourtyard: 'Superiror Courtyard View Room',
    superiorStreetTitle: 'Superior II',
    superiorStreet: 'Superior Street View Room',
    suiteTitle: 'Suites',
    suite: 'Junior Suite',
    showMore: 'SHOW MORE',
  },
  comfortRoom: {
    title: 'COMFORT ROOM',
    subtitle: 'comfort',
    p1: 'Tastefully furnished with intimate, elegant feel, rooms at Cora create a stylish base from which to explore the vibrant neighborhood.',
  },
  superiorCourtyard: {
    title: 'SUPERIOR COURTYARD VIEW ROOM',
    subtitle: 'superior I',
    p1: 'Tastefully furnished with intimate, elegant feel, rooms at Cora create a stylish base from which to explore the vibrant neighborhood.',
  },
  superiorStreet: {
    title: 'SUPERIOR STREET VIEW ROOM',
    subtitle: 'superior II',
    p1: 'Tastefully furnished with intimate, elegant feel, rooms at Cora create a stylish base from which to explore the vibrant neighborhood.',
  },
  juniorSuite: {
    title: 'JUNIOR SUITE',
    subtitle: 'suites',
    p1: 'Soft colors and thoughtful details conspire to create the look and feel of this bright, serene space of modern luxury.',
  },
  stayingAtCora: {
    title: 'STAYING AT CORA',
    restaurantTitle: 'DELICIOUS CORA',
    restaurant:
      "...make yourself comfortable in the Delicious Cora, a contemporary restaurant with an elegantly appointed interior showcasing our chef's culinary vision inspired by the rich variety of Mediterranean fresh, local produce.\n\nBREAKFAST\nfrom 06:30 until 10:30 h\n\nAuthenticity and exquisite flavors are the main features of the signature regional dishes served at our sumptuous breakfast, along with a buffet of international favorites.",
    spaTitle: 'WELLNESS & SPA',
    spa: '...the hotel spa is a serene escape with treatment rooms, a hot tub, sauna, and a small gym. It`s a dedicated space for guests to unwind and rejuvenate within the heart of the hotel.',
    conferenceTitle: 'CONFERENCE VENUE',
    conference:
      '...perfect for smaller to medium-sized meetings or chic private gatherings, the conference venue offers setting and service that ensures every occasion is unforgettable.',
    rooftopTitle: 'CORA CAFFE',
    rooftop:
      '...the rooftop bar, which can be reached via panoramic elevator, is one of the star features of the hotel, offering beverages, handcrafted drinks and views that extend to the mountains on one side and the city skyline on the other.',
  },
  coraCaffe: {
    title: 'CORA CAFFE ROOFTOP BAR',
    subtitle: 'CORA CAFFE',
    p1: 'The rooftop bar, which can be reached via panoramic elevator, is one of the star features of the hotel, offering beverages, handcrafted drinks and views that extend to the mountains on one side and the city skyline on the other.',
    ul1: 'Working hours: ',
    li1: 'Monday - Sunday',
    p2: 'Free parking - 2 hours for guests of Cora Caffe Rooftop Bar',
    p3: 'Cora Caffe Menu',
    offHoursMessage: {
      header: 'Dear guests,',
      p1: 'During the winter season, the Cora Caffe rooftop bar is temporarily closed.',
      p2: 'We will be happy to host you in our bar on the ground floor of the Cora Hotel.',
      footer: 'Cora team',
    },
  },
  spa: {
    title: 'Relaxe and rejuvenate',
    subtitle: 'WELLNESS & SPA',
    p1: 'Savor the luxury of our tranquil spa oasis as you indulge in our array of soothing spa treatments. Let the expert hands of our therapists guide you through a journey of relaxation, allowing you to rejuvenate not only your body but also your mind and soul.',
    p2: 'Wellness facilities for hotel guests (finish sauna, jacuzzi) as per reservation and working hours.Reservation required at least 45min prior to treatment.Please contact our front desk staff in advance if you would like to use wellness facilities out of working hours.',
    p3: 'Prices are quoted in Croatian curreny Kuna and Euro according to the fixed conversion rate of 1EUR = 7,53450HRK (rounded to 2 decimal places) defined by Council Regulation (EU) 2022/1208 from July 12. 2022.',
    massage: {
      title: 'Massages',
      body: {
        title: 'Body rituals',
        l1: 'MER DES INDES - Harmonising massage inspired by Ayurveda',
        l2: 'ILES PACIFIQUE - Lomi-Lomi relaxing massage',
      },
      muscle: {
        title: 'Distress muscle massage',
        subtitle:
          'These services are designed to promote overall wellness and reduce stress, tension and muscle pain.',
      },
      cora: {
        title: 'Cora massage',
        subtitle:
          'Gentle massage that uses essential oils to rpomote relaxation and balance in the body and mind.',
      },
      additional: {
        title: 'Additional service in massage',
        l1: 'Face massage',
        l2: 'Feet massage',
        l3: 'Scalpt massage',
        l4: 'Body Scrub',
      },
    },
    wax: {
      title: 'Wax',
      face: {
        title: 'Face & brows',
        l1: 'Lip',
        l2: 'Chin',
        l3: 'Brows shaping',
        l4: 'Brows colour',
      },
      bikini: {
        title: 'Bikini',
        l2: 'Bikini',
        l1: 'Brazilian',
      },
      body: {
        title: 'Body',
        l1: 'Underarm',
        l2: 'Half arm',
        l3: 'Half leg',
        l4: 'Full arm',
        l5: 'Full leg',
        l6: 'Back or chest',
      },
    },
    treatments: {
      title: 'Treatments',
      face: {
        title: 'Face treatment',
        l1: 'Silicium-Super Lift',
        l2: 'Hyaluprocolagen - Wrinkle Correcting',
        l3: 'Spirulina-Smoothing Detoxifying',
        l4: 'Heart of the Ocean',
      },
      pedicure: {
        title: 'Manicure / Pedicure',
        l1: 'Manicure classic',
        l2: 'Manicure Spa ritual',
        l3: 'Pedicure classic',
        l4: 'Pedicure spa ritual',
      },
      additional: {
        title: 'Additional Service',
        l1: 'CND Shellac',
        l2: 'Soak-off Removal',
        l3: 'Nail polish',
      },
      spa: {
        title: 'Spa zone - Max 2pax',
      },
    },
  },
  gallery: {
    title: 'GALLERY',
  },
  amenities: {
    entertainment: {
      title: 'ENTERTAINMENT',
      tv: 'Smart TV with Cable/Satellite channels',
    },
    bedFeatures: {
      title: 'BED FEATURES',
      sheets: 'High-class collection bed sheets',
      pillow: 'Hypoallergenic pillow',
      pillowMenu: 'Pillow menu',
      service: 'Turndown service',
    },
    bath: {
      title: 'BATHROOM',
      shower: 'Bathroom with bathtub or walk-in shower',
      hairdryer: 'Hairdryer',
      cosmetic: 'Cosmetic assortment',
      scale: 'Weight scale',
      amenities: 'Long-stay amenities (per request)',
      heating: 'Bathroom floor heating',
    },
    office: {
      title: 'OFFICE/TELECOM',
      telephone: 'Telephone',
      wifi: 'Complimentary wireless high-speed internet access',
      wakeUp: 'Wake-up service (per request)',
      elePlug: 'Universal electric plug (per request)',
    },
    dining: {
      title: 'DINING',
      wine: 'Welcome bottle of wine',
      minibar: 'Refrigerated minibar',
      kettle: 'Kettle (tea, coffee)',
    },
    features: {
      title: 'ROOM FEATURES',
      smoke: 'Non-smoking',
      ac: 'Air-conditioning',
      desk: 'Writing desk',
      light: 'Reading light',
      safe: 'In-room electronic safe (accommodates laptop)',
      bathrobe: 'Bathrobes & slippers',
      laundry: 'Laundry bag',
    },
    additional: {
      title: 'ADDITIONAL SERVICES',
      beach: 'Beach accessories (per request)',
      crib: 'Crib (per request)',
      gifts: 'Welcome gifts for children (info available at Front Desk)',
      pets: 'Services for pets (per request)',
    },
  },
  impressum: {
    country: 'Croatia',
    propName: 'Property name',
    propAddress: 'Property address',
    compName: 'Company name',
    compAddress: 'Company address',
    vat: 'Vat',
    commercialCourt: {
      title: 'Commercial court',
      p1: 'The company is signed into a court register at Commercial court in Split under the number 060086339.',
    },
    phone: 'Phone',
  },
};
