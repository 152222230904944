import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import Slider from 'react-slick';
import Constants from '../../utils/Constants';
import variables from '../../style/variables.scss';

interface SlideshowProps {
  name?: string;
  slideshowImages: Array<string>;
}

const Slideshow = (props: SlideshowProps) => {
  const sliderRef = useRef<Slider>(null);
  const sliderModalRef = useRef<Slider>(null);
  const transitionSpeed: number = 500;

  const [activeSlide, setActiveSlide] = useState<number>(0);
  const [activeModalSlide, setActiveModalSlide] = useState<number>(0);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const nextModalSlide = () => {
    if (sliderModalRef.current) {
      sliderModalRef.current.slickNext();
    }
  };

  const prevModalSlide = () => {
    if (sliderModalRef.current) {
      sliderModalRef.current.slickPrev();
    }
  };

  const nextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const prevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  useEffect(() => {
    setActiveModalSlide(activeSlide);

    if (openModal) {
      document.body.classList.add('lock_scroll');
    } else {
      document.body.classList.remove('lock_scroll');
    }
  }, [openModal, activeSlide]);

  useEffect(() => {
    setActiveSlide(0);
  }, []);

  const slickSettings = {
    draggable: false,
    swipe: false,
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    speed: transitionSpeed,
    beforeChange: (current: number, next: number) => {
      setActiveSlide(next);
    },
  };

  const modalSettings = {
    initialSlide: activeSlide,
    adaptiveHeight: true,
    dots: false,
    infinite: true,
    fade: true,
    speed: transitionSpeed,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current: number, next: number) => {
      setActiveModalSlide(next);
    },
  };

  return (
    <>
      {openModal && (
        <div className='slideshow_modal'>
          <div
            className='slideshow_modal_close'
            onClick={() => setOpenModal(!openModal)}
          >
            {Constants.close(variables.secondaryColor)}
          </div>
          <Slider ref={sliderModalRef} {...modalSettings}>
            {props.slideshowImages.map((x: string) => (
              <div className='slideshow_modal_container'>
                <img
                  src={x}
                  alt={`slideshow ${props.slideshowImages.indexOf(x)}`}
                  loading='lazy'
                />
              </div>
            ))}
          </Slider>
          <div className='slideshow_modal_buttons'>
            <div className='opacity_transition_low' onClick={prevModalSlide}>
              {Constants.arrowLeft(variables.secondaryColor)}
            </div>
            <div className='slideshow_modal_buttons_text'>
              {activeModalSlide + 1} / {props.slideshowImages.length}
            </div>
            <div className='opacity_transition_low' onClick={nextModalSlide}>
              {Constants.arrowRight(variables.secondaryColor)}
            </div>
          </div>
        </div>
      )}
      <div className={`slideshow ${!props.name ? '' : undefined}`}>
        <Slider ref={sliderRef} {...slickSettings}>
          {props.slideshowImages.map((x: string) => (
            <div
              className='slideshow_img pointer'
              onClick={() => setOpenModal(!openModal)}
            >
              <img
                src={x}
                alt={`slideshow_image_${props.slideshowImages.indexOf(x)}`}
                loading='lazy'
              />
            </div>
          ))}
        </Slider>
        <div className='slideshow_info'>
          <div className='slideshow_info_buttons'>
            <div className='opacity_transition_low' onClick={prevSlide}>
              {Constants.arrowLeft(variables.secondaryColor)}
            </div>
            <div className='slideshow_info_buttons_text'>
              {activeSlide + 1} / {props.slideshowImages.length}
            </div>
            <div className='opacity_transition_low' onClick={nextSlide}>
              {Constants.arrowRight(variables.secondaryColor)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Slideshow;