import { useEffect, useState } from 'react';

const ScrollDirection = () => {
  const [direction, setDirection] = useState('up');
  let oldScrollY = 0;

  const controlDirection = () => {
    if (window.scrollY > oldScrollY && window.scrollY - oldScrollY > 10) {
      setDirection('down');
    } else if (window.scrollY < oldScrollY && oldScrollY - window.scrollY > 10) {
      setDirection('up');
    }
    oldScrollY = window.scrollY;
  };

  useEffect(() => {
    window.addEventListener('scroll', controlDirection);
    return () => {
      window.removeEventListener('scroll', controlDirection);
    };
  },[]);

  return direction;
};

export default ScrollDirection;
