import About from './components/about/About';
import FacilitiesAndServices from './components/facilitiesAndServices/FacilitiesAndServices';
import RoomsAndSuites from './components/roomsAndSuites/RoomsAndSuites';
import StayingAtCora from './components/stayingAtCora/StayingAtCora';
import Gallery from './components/gallery/Gallery';
import hero from '../../assets/imgs/hero.jpg';
import Constants from '../../utils/Constants';
import { useEffect } from 'react';
import { CapitalizeFirstLeter } from '../../utils/CapitalizeFirstLetter';

const gallery = require.context('../../assets/imgs/home/gallery', true);
const galleryList = gallery.keys().map((image: any) => gallery(image));

const Home = ({ lang }: { lang: Locale.Keys }) => {
  useEffect(() => {
    document.title = `${CapitalizeFirstLeter(Constants.PROPERTY_NAME)}`;
  }, []);

  return (
    <div id={Constants.HOME}>
      <div className='home_container_hero'>
        <img src={hero} alt='hero' />
        <div className='home_container_hero_button'>
          <a
            href={Constants.bookNow}
            target='_blank'
            rel='noreferrer'
            className='opacity_transition_low'
          >
            <div>{lang.menu.bookNow}</div>
          </a>
        </div>
      </div>
      <h1 hidden>Cora Hotel</h1>
      <About lang={lang} />
      <FacilitiesAndServices lang={lang} />
      <RoomsAndSuites lang={lang} />
      <StayingAtCora lang={lang} />
      <Gallery lang={lang} imageList={galleryList} />
    </div>
  );
};

export default Home;
