import ScrollAnimation from '../../../../utils/scrollAnimation';
import coraLogo from '../../../../assets/imgs/cora_logo_white.svg';
import separator from '../../../../assets/imgs/separator.svg';

const About = ({ lang }: { lang: Locale.Keys }) => {
  return (
    <div id='about'>
      <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={500}>
        <div className='about container'>
          <div className='about_logo'>
            <img src={coraLogo} alt='gold korta logo' />
            <h2>
              Stay in Split,
              <br />
              &nbsp;&nbsp;stay in style,
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;stay at Cora
            </h2>
          </div>
          <div className='about_text'>
            <ScrollAnimation animateIn='fadeInUp' duration={500}>
              <h2 className='title_underline_light'>{lang.about.title}</h2>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeInUp' duration={500} delay={100}>
              <p>{lang.about.p1}</p>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeInUp' duration={500} delay={200}>
              <p>{lang.about.p2}</p>
            </ScrollAnimation>
          </div>
        </div>
      </ScrollAnimation>

      <div className='about_img'>
        <img src={separator} alt='separator' />
      </div>
    </div>
  );
};

export default About;
