import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Constants from '../../utils/Constants';
import coraLogo from '../../assets/imgs/cora_logo_white.svg';
import coraMenu from '../../assets/imgs/cora-menu.jpg';
import scrollToPage from '../../utils/ScrollToLink';
import scrollDirection from '../../utils/ScrollDirection';

const Header = ({
  currentLanguage,
  changeLanguage,
  lang,
}: {
  currentLanguage: string;
  changeLanguage: Function;
  lang: Locale.Keys;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const direction = scrollDirection();

  const toggleLanguage = () => {
    const newLanguage =
      currentLanguage === Constants.lang.en
        ? Constants.lang.hr
        : Constants.lang.en;
    changeLanguage(newLanguage);
  };

  const [openMenu, setOpenMenu] = useState<boolean>(false);

  let header: HTMLElement | null = document.getElementById('nav_container');
  let headerBackground: HTMLElement | null =
    document.getElementById('nav_background');
  let logo: HTMLElement | null = document.getElementById('nav_logo');

  const handleLink = (target: string, page?: boolean) => {
    setOpenMenu(!openMenu);

    scrollToPage(target, location, navigate, page);

    setTimeout(() => {
      if (header !== null && headerBackground !== null && window.scrollY > 96) {
        headerBackground.style.top = '-6rem';
        header.style.top = '-6rem';
      }
    }, 600);
  };

  const handelHeaderAppearance = () => {
    if (header !== null && headerBackground !== null) {
      if (direction === 'up') {
        headerBackground.style.top = '0';
        header.style.top = '0';

        if (window.scrollY < 96) {
          headerBackground.style.background = 'transparent';
        }
      } else if (direction === 'down') {
        headerBackground.style.top = '-6rem';
        header.style.top = '-6rem';

        if (window.scrollY >= 96) {
          headerBackground.style.background = Constants.complementaryColorLight;
        }
      }
    }
  };

  window.onscroll = () => {
    handelHeaderAppearance();
  };

  useEffect(() => {
    if (openMenu) {
      document.body.classList.add('lock_scroll');
      document.getElementById('nav_menu')?.classList.add('open_sidemenu');

      if (logo !== null && document.body.clientWidth <= 1016) {
        logo.style.filter = 'invert(1)';
      }
    } else {
      document.body.classList.remove('lock_scroll');
      document.getElementById('nav_menu')?.classList.remove('open_sidemenu');

      if (logo !== null) {
        logo.style.filter = 'invert(0)';
      }
    }
  }, [logo, openMenu]);

  return (
    <div className='nav_wrapper'>
      <div id='nav_container'>
        <div className='nav_menu'>
          <div className='nav_menu_burger'>
            <input
              className='nav_menu_burger_cheeckbox'
              type='checkbox'
              onChange={() => setOpenMenu(!openMenu)}
              checked={openMenu}
            />
            <div>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
        <div
          className='nav_logo'
          onClick={() => scrollToPage(Constants.HOME, location, navigate)}
        >
          <img src={coraLogo} alt='cora logo' id='nav_logo' />
        </div>
        <div className='nav_book'>
          <div
            id='header_lang'
            onClick={toggleLanguage}
            className='opacity_transition_low nav_book_lang'
          >
            {currentLanguage === Constants.lang.en ? 'HR' : 'EN'}
          </div>
          <a
            href={Constants.bookNow}
            target='_blank'
            rel='noreferrer'
            className='opacity_transition_low'
          >
            <div>{lang.menu.bookNow}</div>
          </a>
        </div>
      </div>
      <div id='nav_background'></div>
      <div id='nav_menu'>
        <div className='nav_menu_list'>
          <div className='nav_menu_list_links'>
            <div
              className='opacity_transition_high'
              onClick={() => handleLink(Constants.HOME)}
            >
              {Constants.HOME}
            </div>
            <div
              className='opacity_transition_high'
              onClick={() => handleLink('about')}
            >
              {lang.menu.ourStory.toLowerCase()}
            </div>
            <div
              className='opacity_transition_high'
              onClick={() => handleLink('accommodations')}
            >
              {lang.menu.roomsSuites.toLowerCase()}
            </div>
            <div
              className='opacity_transition_high'
              onClick={() => handleLink('stayingCora')}
            >
              {lang.menu.stayingCora.toLowerCase()}
            </div>
            <div
              className='opacity_transition_high'
              onClick={() => handleLink(Constants.CAFFE, true)}
            >
              {lang.menu.coraCaffe.toLowerCase()}
            </div>
            <div
              className='opacity_transition_high'
              onClick={() => handleLink(Constants.SPA, true)}
            >
              {lang.menu.spa.toLowerCase()}
            </div>
            <div
              className='opacity_transition_high'
              onClick={() => handleLink('gallery')}
            >
              {lang.menu.gallery.toLowerCase()}
            </div>
            <div
              className='opacity_transition_high'
              onClick={() => handleLink('footer')}
            >
              {lang.menu.contactUs.toLowerCase()}
            </div>
            <div
              id='menu_lang'
              onClick={toggleLanguage}
              className='opacity_transition_low nav_book_lang'
            >
              {currentLanguage === Constants.lang.en ? 'HR' : 'EN'}
            </div>
          </div>
          <div className='nav_menu_list_image'>
            <img src={coraMenu} alt='cora menu' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
