import Constants from '../../utils/Constants';
import ScrollAnimation from '../../utils/scrollAnimation';
import separator from '../../assets/imgs/separator.svg';
import menu from '../../assets/documents/CORA_CAFFE_MENU.pdf';
import OffHoursMessage from './components/OffHoursMessage';
import { useEffect } from 'react';
import { CapitalizeFirstLeter } from '../../utils/CapitalizeFirstLetter';
import Gallery from '../home/components/gallery/Gallery';

const images = require.context('../../assets/imgs/coraCaffe', true);
const imageList = images.keys().map((image: any) => images(image));

const CoraCaffe = ({ lang }: { lang: Locale.Keys }) => {
  useEffect(() => {
    document.title = `${CapitalizeFirstLeter(
      Constants.PROPERTY_NAME
    )} ${CapitalizeFirstLeter(Constants.CAFFE)}`;
  }, []);

  return (
    <div id={Constants.CAFFE} className='caffe_wrapper'>
      <div className='caffe_hero'>
        <img src={imageList[3]} alt={`${lang.coraCaffe.subtitle} hero`} />
        <div className='caffe_hero_title'>
          <h1>{lang.coraCaffe.subtitle}</h1>
        </div>
      </div>
      <div className='caffe_img'>
        <img src={separator} alt='separator' />
      </div>
      <div className='caffe_container container'>
        <div className='caffe_container_info'>
          <ScrollAnimation
            animateIn='fadeIn'
            animateOut='fadeOut'
            duration={500}
          >
            <div className='caffe_container_info_text'>
              <ScrollAnimation animateIn='fadeInUp' duration={500} delay={100}>
                <h2>{lang.coraCaffe.title}</h2>
                {lang.coraCaffe.p1}
              </ScrollAnimation>
              <ScrollAnimation animateIn='fadeInUp' duration={500} delay={200}>
                <div className='caffe_container_info_details'>
                  <p>{lang.coraCaffe.ul1.toUpperCase()}</p>
                  <ul>
                    <li>
                      {lang.coraCaffe.li1} <b>08:00 - 22:00</b>
                    </li>
                  </ul>
                </div>
              </ScrollAnimation>
              <OffHoursMessage lang={lang} disabled={true} />
              <ScrollAnimation animateIn='fadeInUp' duration={500} delay={300}>
                <p>{lang.coraCaffe.p2}</p>
              </ScrollAnimation>
              <ScrollAnimation animateIn='fadeInUp' duration={500} delay={400}>
                <a
                  className='caffe_container_info_text_button'
                  href={menu}
                  target='_blank'
                  rel='noreferrer'
                >
                  {lang.coraCaffe.p3.toUpperCase()}
                </a>
              </ScrollAnimation>
            </div>
          </ScrollAnimation>
        </div>
      </div>
      <Gallery lang={lang} imageList={imageList} />
    </div>
  );
};

export default CoraCaffe;
