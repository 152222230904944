import { useEffect } from 'react';
import heroImg from '../../assets/imgs/home/gallery/16.jpg';
import { CapitalizeFirstLeter } from '../../utils/CapitalizeFirstLetter';
import Constants from '../../utils/Constants';

const Rules = ({ lang }: { lang: Locale.Keys }) => {
  useEffect(() => {
    document.title = `${CapitalizeFirstLeter(
      Constants.PROPERTY_NAME
    )} - ${CapitalizeFirstLeter(Constants.RULES)}`;
  }, []);

  return (
    <div id={Constants.RULES} className='rules'>
      <div className='rules_hero'>
        <img src={heroImg} alt='rules hero' />
      </div>
      <div className='rules_container container'>
        <h1>Hotel Rules</h1>
        <p>
          Cora Hotel is operated by the Company MANAS D.O.O. with headquarters
          in Barakovićeva 18, HR - 21 000 Split, Croatia, registered in the
          Commercial Court in City of Split under MBS: 060086339 and with VAT ID
          no.: HR 77290534017.
        </p>
        <h3>Room features</h3>
        <p>
          All 34 individually furnished rooms feature comforts such as pillow
          menus and laptop-compatible safes, in addition to thoughtful touches
          like laptop-friendly workspaces and air conditioning.
        </p>
        <p>Extra conveniences in all rooms include:</p>
        <ul>
          <li>Highchairs and free infant beds</li>
          <li>
            Hypo-allergenic bedding, pillowtop mattresses, and down comforters
          </li>
          <li>Bathrooms with showers and free toiletries</li>
          <li>Flat-screen TVs with satellite channels</li>
          <li>Private yards, wardrobes/closets, and electric kettles</li>
        </ul>
        <h3>Check-in</h3>
        <p>
          Check-in from 3:00 PM Early check-in subject to availability Minimum
          check-in age - 18
        </p>
        <h3>Miscellaneous</h3>
        <p>
          This property offers transfers from the cruise terminal, ferry
          terminal, airport, and train station (surcharges may apply); to
          arrange pick-up, contact the property 48 hours prior to arrival, using
          the contact information on the booking confirmation
        </p>
        <div className='rules_container_section'>
          <h2>Important informations</h2>
          <h3>Fees</h3>
          <p>You'll be asked to pay the following charges at the property:</p>
          <ul>
            <li>
              A tax is imposed by the city and collected at the property.
              Exemptions or reductions might apply. For more details, please
              contact the property using the information on the reservation
              confirmation received after booking.
            </li>
            <li>Bathrooms with showers and free toiletries</li>
            <li>
              We have included all charges provided to us by the property.
            </li>
          </ul>
          <h3>Optional extras*</h3>
          <p>Airport shuttle fee: from EUR 79 per vehicle (one-way)</p>
          <p>Self parking fee: EUR 15 per day (in/out privileges)</p>
          <p className='rules_container_section_small_print'>
            *The above list may not be comprehensive. Fees and deposits may not
            include tax and are subject to change.
          </p>
          <h3>You need to know</h3>
          <p>
            This property is managed by a professional host. The provision of
            housing is linked to their trade, business, or profession.
          </p>
          <p>
            Extra-person charges may apply and vary depending on property
            policy.
          </p>
          <p>
            Government-issued photo identification and a cash deposit may be
            required at check-in for incidental charges.
          </p>
          <p>
            Special requests are subject to availability upon check-in and may
            incur additional charges; special requests cannot be guaranteed.
          </p>
          <p>
            Guests must contact this property in advance to reserve cribs/infant
            beds, rollaway/extra beds, sofa beds, and high chair.
          </p>
          <p>Onsite parties or group events are strictly prohibited.</p>
          <p>
            Safety features at this property include a carbon monoxide detector,
            a fire extinguisher, a smoke detector, a security system, a first
            aid kit, and window guards.
          </p>
          <p>We should mention:</p>
          <ul>
            <li>Only registered guests are allowed in the guestrooms</li>
            <li>
              This property welcomes guests of all sexual orientations and
              gender identities (LGBTQ friendly)
            </li>
          </ul>
        </div>
        <div className='rules_container_section'>
          <h2>Cancellation / prepayment</h2>
          <p>
            Cora Hotel offers free cancellation on select room rates because
            flexibility matters! Cancellation and prepayment policies vary
            according to apartment type. Please choose the dates of your stay
            and check the conditions of your required room.
          </p>
          <h3>Fully refundable cancellation policy (100% refund)</h3>
          <p>
            For the units offering free cancellation, paid amount is fully
            refundable if cancellation is made no less than the indicated number
            of days prior to arrival.
          </p>
          <h3>Non-Refundable cancellation policy</h3>
          <p>
            For the units with indicated Non-refundable rate, if you change or
            cancel your booking you will not get a refund or credit to use for a
            future stay. This policy will apply regardless of COVID-19, subject
            to any local consumer laws.
          </p>
        </div>
        <div className='rules_container_section'>
          <h2>Children and beds</h2>
          <h3>Child policies</h3>
          <p>Children of any age are welcome.</p>
          <p>
            Children aged 12 years and above are considered adults at this
            property.
          </p>
          <p>
            To see correct prices and occupancy information, please add the
            number of children in your group and their ages to your search.
          </p>
          <h3>Cot and extra bed policies</h3>
          <p>0 - 2 years, the cot is free upon request.</p>
          <p>
            The number of cots allowed is dependent on the option you choose.
            Please check your selected option for more information.
          </p>
          <p>All cots are subject to availability.</p>
          <h3>PAYMENTS</h3>
          <p>
            During your stay you can pay for any extras using Maestro,
            Mastercard, Visa, UnionPay credit card and Diners Club.
          </p>
        </div>
        <div className='rules_container_section'>
          <h2>Restrictions</h2>
          <h3>Smoking</h3>
          <p>Smoking is not allowed.</p>
          <h3>Parties</h3>
          <p>Parties / events are not allowed</p>
          <h3>Quiet hours</h3>
          <p>Guests must be quiet between 22:00 and 08:00.</p>
          <p>Pets</p>
        </div>
        <div className='rules_container_section'>
          <h2>Notice on how to submit complaints</h2>
          <p>
            It is in the interest of the guest to try to solve his complaint
            immediately at the destination. The guest is obliged to cooperate
            with the hotel staff in good faith to eliminate the causes of the
            complaint. If there is no improvement even after a complaint, the
            guest must ask for a confirmation that shows that the service was
            not provided, or that it was not provided in the manner agreed upon.
            The guest must attach the confirmation to the written complaint.
          </p>
          <p>
            The guest must file a written complaint within 8 days after using
            the service. If the guest submits a written complaint after that
            deadline, the Hotel is not obliged to take such complaint into
            account. The hotel will resolve only those complaints that could not
            be resolved on the spot.
          </p>
          <p>
            Pursuant to Art. 10 of the Consumer Protection Act, complaints about
            the purchased product or the service provided must be submitted in
            writing to the address: MANAS d.o.o., Cora Hotel, Put Supavla 39,
            21000 Split, Croatia or electronically to the address
            reservations@cora-hotel.com with the name and surname, and the
            contact for the delivery of the a The hotel delivers the response to
            the complaint in written form no later than 15 days from the day of
            receipt of the complaint, to the address that the guest left as a
            contact, or to the address from which the complaint was received
          </p>
        </div>
        <div className='rules_container_section'>
          <h2>Jurisdiction</h2>
          <p>
            All possible disputes or disagreements will be resolved peacefully
            and by agreement in a manner acceptable to both parties.
          </p>
          <p>
            If a solution cannot be reached, they contract the local
            jurisdiction of the Commercial Court in Split.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Rules;
