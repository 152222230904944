import ScrollAnimation from '../../../../utils/scrollAnimation';
import coraLogo from '../../../../assets/imgs/cora_logo_white.svg';
import separator from '../../../../assets/imgs/separator.svg';

const About = ({ lang }: { lang: Locale.Keys }) => {
  return (
    <div id='spa_about'>
      <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={500}>
        <div className='spa_about container'>
          <div className='spa_about_logo'>
            <img src={coraLogo} alt='cora logo' />
            <h2>
              RELAX &,
              <br />
              &nbsp;&nbsp;REVIVE,
            </h2>
          </div>
          <div className='spa_about_text'>
            <ScrollAnimation animateIn='fadeInUp' duration={500}>
              <h2 className='title_underline_light'>{lang.spa.title}</h2>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeInUp' duration={500} delay={100}>
              <p>{lang.spa.p1}</p>
            </ScrollAnimation>
          </div>
        </div>
      </ScrollAnimation>

      <div className='spa_about_img'>
        <img src={separator} alt='separator' />
      </div>
    </div>
  );
};

export default About;
