import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useEffect } from 'react';
import { useLanguageSwitcher } from './locale/useLanguageSwitcher';
import { hr } from './locale/hr';
import { en } from './locale/en';
import Layout from './pages/Layout';
import Home from './pages/home/Home';
import ComfortRoom from './pages/roomsAndSuites/rooms/ComfortRoom';
import SuperiorCourtyard from './pages/roomsAndSuites/rooms/SuperiorCourtyard';
import SuperiorStreet from './pages/roomsAndSuites/rooms/SuperiorStreet';
import JuniorSuite from './pages/roomsAndSuites/suites/JuniorSuite';
import CoraCaffe from './pages/coraCaffe/CoraCaffe';
import Spa from './pages/spa/Spa';
// import Impressum from './pages/impressum/Impressum';
// import Rules from './pages/rules/Rules';
import OnReloadScrollToTop from './utils/OnReloadScrollToTop';
import Constants from './utils/Constants';
import Impressum from './pages/impressum/Impressum';
import Rules from './pages/rules/Rules';
import PrivacyPolicy from './pages/privacyPolicy/PrivacyPolicy';

const App: React.FC = () => {
  const { currentLanguage, changeLanguage, getLanguageFromURL } =
    useLanguageSwitcher();

  const lang: Locale.Keys = currentLanguage === Constants.lang.en ? en : hr;
  let doc: HTMLElement = document.documentElement;

  OnReloadScrollToTop();

  const setHight = () => {
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  };

  window.addEventListener('resize', setHight);

  useEffect(() => {
    getLanguageFromURL();
  }, [getLanguageFromURL]);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          element={
            <Layout
              currentLanguage={currentLanguage}
              changeLanguage={changeLanguage}
              lang={lang}
            />
          }
        >
          <Route index element={<Home lang={lang} />} />
          <Route
            path={Constants.COMFORT_ROOM}
            element={<ComfortRoom lang={lang} />}
          />
          <Route
            path={Constants.SUPERIOR_COURTYARD}
            element={<SuperiorCourtyard lang={lang} />}
          />
          <Route
            path={Constants.SUPERIOR_STREET}
            element={<SuperiorStreet lang={lang} />}
          />
          <Route
            path={Constants.JUNIOR_SUITE}
            element={<JuniorSuite lang={lang} />}
          />
          <Route path={Constants.CAFFE} element={<CoraCaffe lang={lang} />} />
          <Route path={Constants.SPA} element={<Spa lang={lang} />} />
          <Route
            path={Constants.IMPRESSUM}
            element={<Impressum lang={lang} />}
          />
          <Route path={Constants.RULES} element={<Rules lang={lang} />} />
          <Route path={Constants.PRIVACY_POLICY} element={<PrivacyPolicy lang={lang} />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
