import ScrollAnimation from '../../../../utils/scrollAnimation';

const Masssages = ({ lang }: { lang: Locale.Keys }) => {
  return (
    <div id='masssages'>
      <div className='container'>
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={500}>
          <h2 className='title_underline_dark'>{lang.spa.massage.title}</h2>
          <div className='offers_section_row'>
            <ScrollAnimation animateIn='fadeInUp' duration={500} delay={50}>
              <p>{lang.spa.massage.body.title}</p>
              <ul>
                <li>{lang.spa.massage.body.l1} 90min - 185EUR</li>
                <li>{lang.spa.massage.body.l2} 90min - 185EUR</li>
              </ul>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeInUp' duration={500} delay={100}>
              <p>{lang.spa.massage.muscle.title}</p>
              <span>{lang.spa.massage.muscle.subtitle}</span>
              <ul>
                <li>25min - 50EUR</li>
                <li>50min - 80EUR</li>
                <li>80min - 120EUR</li>
              </ul>
            </ScrollAnimation>
          </div>

          <div className='offers_section_row'>
            <ScrollAnimation animateIn='fadeInUp' duration={500} delay={150}>
              <p>{lang.spa.massage.cora.title}</p>
              <span>{lang.spa.massage.cora.subtitle}</span>
              <ul>
                <li>25min - 40EUR</li>
                <li>50min - 70EUR</li>
                <li>80min - 1OOEUR</li>
              </ul>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeInUp' duration={500} delay={200}>
              <p>{lang.spa.massage.additional.title}</p>
              <ul>
                <li>{lang.spa.massage.additional.l1} 20min - 30EUR</li>
                <li>{lang.spa.massage.additional.l2} 25min - 40EUR</li>
                <li>{lang.spa.massage.additional.l3} 15min - 15EUR</li>
                <li>{lang.spa.massage.additional.l4} 25min - 40EUR</li>
              </ul>
            </ScrollAnimation>
          </div>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default Masssages;
