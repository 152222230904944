import ScrollAnimation from '../../../../utils/scrollAnimation';

const Treatments = ({ lang }: { lang: Locale.Keys }) => {
  return (
    <div id='treatments'>
      <div className='container'>
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={500}>
          <h2 className='title_underline_dark'>{lang.spa.treatments.title}</h2>
          <div className='offers_section_row'>
            <ScrollAnimation animateIn='fadeInUp' duration={500} delay={50}>
              <p>{lang.spa.treatments.face.title}</p>
              <ul>
                <li>{lang.spa.treatments.face.l1} 70min - 135EUR</li>
                <li>{lang.spa.treatments.face.l2} 60min - 125EUR</li>
                <li>{lang.spa.treatments.face.l3} 60min - 115EUR</li>
                <li>{lang.spa.treatments.face.l4} 60min - 85EUR</li>
              </ul>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeInUp' duration={500} delay={100}>
              <p>{lang.spa.treatments.pedicure.title}</p>
              <ul>
                <li>{lang.spa.treatments.pedicure.l1} - 30EUR</li>
                <li>{lang.spa.treatments.pedicure.l2} - 45EUR</li>
                <li>{lang.spa.treatments.pedicure.l3} - 45EUR</li>
                <li>{lang.spa.treatments.pedicure.l4} - 55EUR</li>
              </ul>
            </ScrollAnimation>
          </div>

          <div className='offers_section_row'>
            <ScrollAnimation animateIn='fadeInUp' duration={500} delay={150}>
              <p>{lang.spa.treatments.additional.title}</p>
              <ul>
                <li>{lang.spa.treatments.additional.l1} - 20EUR</li>
                <li>{lang.spa.treatments.additional.l2} - 1OEUR</li>
                <li>{lang.spa.treatments.additional.l3} - 1OEUR</li>
              </ul>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeInUp' duration={500} delay={200}>
              <p>{lang.spa.treatments.spa.title}*</p>
              <ul>
                <li>45min - 40EUR</li>
              </ul>
            </ScrollAnimation>
          </div>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default Treatments;
