import React, { useState } from 'react';
import Slider from 'react-slick';
import Constants from '../../../../utils/Constants';
import rooftopImg from '../../../../assets/imgs/home/stayingAtCora/rooftop.jpg';
import restaurantImg from '../../../../assets/imgs/home/stayingAtCora/restaurant.jpg';
import conferenceImg from '../../../../assets/imgs/home/stayingAtCora/conference.jpg';
import spaImg from '../../../../assets/imgs/home/stayingAtCora/spa.jpg';
import ScrollAnimation from '../../../../utils/scrollAnimation';
import scrollToPage from '../../../../utils/ScrollToLink';
import { useLocation, useNavigate } from 'react-router-dom';

const StayingAtCora = ({ lang }: { lang: Locale.Keys }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const restaurant = () => {
    return (
      <>
        <h2 className='title_underline_dark_small'>
          {lang.stayingAtCora.restaurantTitle}
        </h2>
        <p>{lang.stayingAtCora.restaurant}</p>
      </>
    );
  };

  const spa = () => {
    return (
      <>
        <h2 className='title_underline_dark_small'>
          {lang.stayingAtCora.spaTitle}
        </h2>
        <p>{lang.stayingAtCora.spa}</p>
        <div className='stayingCoraButton'>
          <div
            onClick={() =>
              scrollToPage(Constants.SPA, location, navigate, true)
            }
          >
            {lang.roomsAndSuites.showMore}
          </div>
        </div>
      </>
    );
  };

  const conference = () => {
    return (
      <>
        <h2 className='title_underline_dark_small'>
          {lang.stayingAtCora.conferenceTitle}
        </h2>
        <p>{lang.stayingAtCora.conference}</p>
      </>
    );
  };

  const rooftop = () => {
    return (
      <>
        <h2 className='title_underline_dark_small'>
          {lang.stayingAtCora.rooftopTitle}
        </h2>
        <p>{lang.stayingAtCora.rooftop}</p>
        <div className='stayingCoraButton'>
          <div
            onClick={() =>
              scrollToPage(Constants.CAFFE, location, navigate, true)
            }
          >
            {lang.roomsAndSuites.showMore}
          </div>
        </div>
      </>
    );
  };

  const [count, setCount] = useState<number>(1);
  const [preventDoubleClick, setPreventDoubleClick] = useState<boolean>(false);
  const transitionSpeed: number = 500;

  const stayingCoraTexts: any[] = [rooftop, restaurant, conference, spa];
  const stayingCoraImgs: string[] = [
    rooftopImg,
    restaurantImg,
    conferenceImg,
    spaImg,
  ];

  const sliderText = React.useRef<Slider | null>(null);
  const sliderImg = React.useRef<Slider | null>(null);

  const nextSlide = () => {
    if (!preventDoubleClick) {
      setPreventDoubleClick(true);

      sliderText.current?.slickNext();
      sliderImg.current?.slickNext();

      if (count === stayingCoraImgs.length) {
        setCount(1);
      } else {
        setCount(count + 1);
      }

      setTimeout(function () {
        setPreventDoubleClick(false);
      }, transitionSpeed + 100);
    }
  };

  const prevSlide = () => {
    if (!preventDoubleClick) {
      setPreventDoubleClick(true);

      sliderText.current?.slickPrev();
      sliderImg.current?.slickPrev();

      if (count === 1) {
        setCount(stayingCoraImgs.length);
      } else {
        setCount(count - 1);
      }

      setTimeout(function () {
        setPreventDoubleClick(false);
      }, transitionSpeed);
    }
  };

  const slickSettingsOne = {
    adaptiveHeight: true,
    draggable: false,
    swipe: false,
    arrows: false,
    fade: true,
    infinite: true,
    speed: transitionSpeed,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const slickSettingsTwo = {
    adaptiveHeight: false,
    draggable: false,
    swipe: false,
    arrows: false,
    fade: true,
    infinite: true,
    speed: transitionSpeed,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div id='stayingCora'>
      <div className='discover container'>
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={500}>
          <ScrollAnimation animateIn='fadeIn' duration={1000}>
            <h2 className='text_center'>{lang.stayingAtCora.title}</h2>
            <div className='discover_container'>
              <div className='discover_container_text'>
                <Slider ref={sliderText} {...slickSettingsOne}>
                  {stayingCoraTexts.map((x: any) => x())}
                </Slider>
                <div className='discover_container_buttons'>
                  <div
                    className='opacity_transition_low'
                    onClick={() => prevSlide()}
                  >
                    {Constants.arrowLeft(Constants.complementaryColorDark)}
                  </div>
                  <div
                    className='opacity_transition_low'
                    onClick={() => nextSlide()}
                  >
                    {Constants.arrowRight(Constants.complementaryColorDark)}
                  </div>
                  <div className='discover_container_buttons_counter'>
                    {count} / {stayingCoraTexts.length}
                  </div>
                </div>
              </div>
              <div className='discover_container_img'>
                <Slider ref={sliderImg} {...slickSettingsTwo}>
                  {stayingCoraImgs.map((x: string) => (
                    <img
                      src={x}
                      alt={`staying at cora ${
                        stayingCoraImgs[stayingCoraImgs.indexOf(x)]
                      }`}
                    />
                  ))}
                </Slider>
              </div>
            </div>
          </ScrollAnimation>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default StayingAtCora;
