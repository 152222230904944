import { useLocation, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import comfort from '../../../../assets/imgs/home/roomsAndSuites/comfort.jpg';
import superior_courtyard from '../../../../assets/imgs/home/roomsAndSuites/superior_courtyard.jpg';
import superior_street from '../../../../assets/imgs/home/roomsAndSuites/superior_street.jpg';
import suites from '../../../../assets/imgs/home/roomsAndSuites/suites.jpg';
import scrollToPage from '../../../../utils/ScrollToLink';
import Constants from '../../../../utils/Constants';
import ScrollAnimation from '../../../../utils/scrollAnimation';

interface Accommodation {
  accommodationtTitle: string;
  accommodationtText: string;
  accommodationtLink: string;
  accommodationtImage: string;
}

const RoomsAndSuites = ({ lang }: { lang: Locale.Keys }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const accommodations: Array<Accommodation> = [
    {
      accommodationtTitle: lang.roomsAndSuites.comfortTitle,
      accommodationtText: lang.roomsAndSuites.comfort,
      accommodationtLink: Constants.COMFORT_ROOM,
      accommodationtImage: comfort,
    },
    {
      accommodationtTitle: lang.roomsAndSuites.superiorCourtyardTitle,
      accommodationtText: lang.roomsAndSuites.superiorCourtyard,
      accommodationtLink: Constants.SUPERIOR_COURTYARD,
      accommodationtImage: superior_courtyard,
    },
    {
      accommodationtTitle: lang.roomsAndSuites.superiorStreetTitle,
      accommodationtText: lang.roomsAndSuites.superiorStreet,
      accommodationtLink: Constants.SUPERIOR_STREET,
      accommodationtImage: superior_street,
    },
    {
      accommodationtTitle: lang.roomsAndSuites.suiteTitle,
      accommodationtText: lang.roomsAndSuites.suite,
      accommodationtLink: Constants.JUNIOR_SUITE,
      accommodationtImage: suites,
    },
  ];

  const slickSettings = {
    draggable: false,
    swipe: false,
    arrows: false,
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: (i: number) => (
      <div>{accommodations[i].accommodationtTitle}</div>
    ),
  };

  return (
    <div id='accommodations'>
      <div className='accommodations container'>
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={500}>
          <h2 className='text_center'>{lang.roomsAndSuites.title}</h2>
          <ScrollAnimation animateIn='fadeInUp' duration={500} delay={100}>
            <h3 className='title_underline_dark_small'>
              {lang.roomsAndSuites.subtitle}
            </h3>
            <p>{lang.roomsAndSuites.p1}</p>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeIn' duration={1000} delay={200}>
            <Slider {...slickSettings} className='accommodations_slick'>
              {accommodations.map((x: Accommodation) => (
                <div className='accommodations_slick_container'>
                  <img
                    src={x.accommodationtImage}
                    alt={x.accommodationtTitle}
                    className='accommodations_slick_container_image'
                  />
                  <div
                    data-index={`${accommodations.indexOf(x)}`}
                    className='accommodations_slick_container_box'
                  >
                    <div className='accommodations_slick_container_box_title title_underline_complementary_small'>
                      {x.accommodationtText}
                    </div>
                    <div className='accommodations_slick_container_box_links'>
                      <a
                        href={Constants.bookNow}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <div>{lang.menu.bookNow}</div>
                      </a>
                      <div
                        onClick={() =>
                          scrollToPage(
                            x.accommodationtLink,
                            location,
                            navigate,
                            true
                          )
                        }
                      >
                        {lang.roomsAndSuites.showMore}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </ScrollAnimation>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default RoomsAndSuites;
