import Constants from '../../../utils/Constants';
import ScrollAnimation from '../../../utils/scrollAnimation';
import Amenities from '../components/Amenities';
import separator from '../../../assets/imgs/separator.svg';
import { useEffect } from 'react';
import { CapitalizeFirstLeter } from '../../../utils/CapitalizeFirstLetter';
import Gallery from '../../home/components/gallery/Gallery';

const images = require.context(
  '../../../assets/imgs/roomsAndSuites/superiorStreet',
  true
);
const imageList = images.keys().map((image: any) => images(image));

const SuperirorStreet = ({ lang }: { lang: Locale.Keys }) => {
  useEffect(() => {
    document.title = `${CapitalizeFirstLeter(
      Constants.PROPERTY_NAME
    )} - ${CapitalizeFirstLeter(lang.superiorStreet.subtitle)}`;
  }, [lang]);
  
  return (
    <div id={Constants.SUPERIOR_STREET} className='accommodations_wrapper'>
      <div className='accommodations_hero'>
        <img src={imageList[8]} alt={`${lang.superiorStreet.subtitle} hero`} />
        <div className='accommodations_hero_title'>
          <h1>{lang.superiorStreet.subtitle}</h1>
        </div>
      </div>
      <div className='accommodations_img'>
        <img src={separator} alt='separator' />
      </div>
      <div className='accommodations_container container'>
        <div className='accommodations_container_info'>
          <ScrollAnimation
            animateIn='fadeIn'
            animateOut='fadeOut'
            duration={500}
          >
            <div className='accommodations_container_info_text'>
              <ScrollAnimation animateIn='fadeInUp' duration={500}>
                <h2 className='title_underline_dark'>
                  {lang.superiorStreet.title}
                </h2>
                {lang.superiorStreet.p1}
              </ScrollAnimation>
              <ScrollAnimation animateIn='fadeInUp' duration={500} delay={100}>
                <div className='accommodations_container_info_text_button'>
                  <a
                    href={Constants.bookNow}
                    target='_blank'
                    rel='noreferrer'
                    className='opacity_transition_low'
                  >
                    <div>{lang.menu.bookNow}</div>
                  </a>
                </div>
              </ScrollAnimation>
            </div>
            <Amenities lang={lang} />
          </ScrollAnimation>
        </div>
      </div>
      <Gallery lang={lang} imageList={imageList} />
    </div>
  );
};

export default SuperirorStreet;
