import { useEffect } from 'react';
import heroImg from '../../assets/imgs/home/gallery/16.jpg';
import { CapitalizeFirstLeter } from '../../utils/CapitalizeFirstLetter';
import Constants from '../../utils/Constants';

const PrivacyPolicy = ({ lang }: { lang: Locale.Keys }) => {
  useEffect(() => {
    document.title = `${CapitalizeFirstLeter(
      Constants.PROPERTY_NAME
    )} - ${CapitalizeFirstLeter(Constants.PRIVACY_POLICY)}`;
  }, []);

  return (
    <div id={Constants.PRIVACY_POLICY} className='privacy'>
      <div className='privacy_hero'>
        <img src={heroImg} alt='privacy policy hero' />
      </div>
      <div className='privacy_container container'>
        <h1>Privacy Policy</h1>
        <p className='text_center'>
          STATEMENT ON PROTECTION OF PERSONAL DATA OF THE COMPANY MANAS d.o.o.
        </p>
        <p className='text_center'>
          This Statement on personal data protection for the company
          <br />
          MANAS doo, 21000 Split, Barakovićeva 18, OIB 77290534017, VAT:
          HR77290534017
          <br />
          (hereinafter Cora Hotel), was prepared following General Regulation
          (EU) No. 2016/679 of the European Parliament and of the Council of 27
          April 2016, which applies from May 25, 2018.
        </p>
        <p className='text_center'>
          Cora Hotel, 21000 Split, Put Supavla 39 will handle your personal data
          following General Regulation (EU) 2016/679 on the protection of
          individuals concerning the processing of personal dana and the free
          movement of such data, the Law on Implementation of the General
          Regulation personal data (NN 42/2018) or other national law based on
          the Regulation in question and with the application of appropriate
          technical and security measures to protect personal data from
          unauthorized access, misuse, disclosure, loss or destruction.
        </p>
        <div className='privacy_container_section'>
          <h2>1. General information</h2>
          <p>
            This Statement describes what data we collect, how we process it,
            and for what purposes we use it, as well as your rights related to
            your data. The deadline for responding to a complaint is 15 days.
            The competent court is the municipal court in Split.
          </p>
          <p>Cora hotel is responsible for data processing:</p>
          <ul>
            <li>Contact e-mail address: voditelj.obrade@manas.hr</li>
            <li>Contact phone: +385 (0) 21 644 200</li>
            <li>
              Contact of the Personal Data Protection Officer:
              voditelj.obrade@manas.hr
            </li>
          </ul>
          <p>
            If we use the services of external providers to process your
            personal data, it is the processing (of personal data) by order and
            we are also responsible for the protection of your personal data.
          </p>
        </div>
        <div className='privacy_container_section'>
          <h2>2. The types of personal data we process</h2>
          <p>
            Cora hotel processes the personal data that you have entrusted to us
            by accessing our official website or by accessing our official
            profiles on social networks, requesting contact, requesting
            information about the business of the Processing Manager, expressing
            remarks or compliments, by submitting applications for a job, by
            requesting to receive a newsletter, and by entering into a business
            relationship with us.
          </p>
          <p>
            Personal data is collected by sending an e-mail to the e-mail
            addresses of the Processing Manager or by calling the telephone
            number of the Processing Manager listed on our official website and
            your posting on our official social media profiles, based on your
            contact request, request for information on the business of the
            Processing Manager, expressing a remark or praise, submitting
            applications for a job, requesting a newsletter and entering into a
            business relationship with us are your name, your e-mail address,
            your phone number, your resume, etc. and all those personal data
            that you have provided to us on your own initiative.
          </p>
          <p>
            Personal data is collected through the contact form on our official
            website, and based on your request for information about our
            services, products or business and the request to enter into a
            business or contractual relationship with us are your name, e-mail
            address, and number phone.
          </p>
          <p>
            Personal data is collected through the accommodation booking form on
            our official website or through the guest registration form, and
            based on your request for booking our services or products or guest
            registration are your name, address, e-mail address, telephone
            number and all those data that are necessary in order to make a
            reservation of the same or for the purpose of performing the
            contracted services or products.
          </p>
          <p>
            Personal data collected for the purpose of contacting
            representatives / owners of business associates and/or clients of
            legal entities and/or employees of business associates and clients,
            and for the efficiency of business contracts are the name and
            surname of the person authorized to represent /owner, the ID of the
            person authorized to represent / owner, address of residence, ie
            residence of the person authorized to represent / owner, name and
            surname of employees of business partners - suppliers, the position
            of employees of business associates, place of work of employees of
            business associates, etc.
          </p>
          <p>
            Personal data collected for the purpose of providing adequate
            wellness treatment concerning the health condition of the service
            user and prevention of possible injuries of the user and the
            insurance of the Processing Manager in case of claiming damages by
            the user are your health data.
          </p>
          <p>
            Personal data collected for the purpose of subsequent payment for
            products/services that the guest did not report when checking out of
            the hotel is your credit card information.
          </p>
          <p>
            By entering the video surveillance system of the Processing Manager,
            it is possible to collect personal video data. The video is
            collected to protect persons and property.
          </p>
          <p>
            As part of the business relationship, you are obliged to make
            available personal data that are necessary for the establishment and
            implementation of the business relationship and the fulfillment of
            related contractual obligations or for the collection of which there
            is an obligation. Without this information, we are generally forced
            to refuse to conclude a contract, execute an order or suspend
            execution and terminate an existing contract. You are not required
            to give your consent to the processing of that data which are not
            relevant or prescribed by law for the performance of the contract.
          </p>
        </div>
        <div className='privacy_container_section'>
          <h2>3. Legal basis and purposes of personal data processing</h2>
          <p>
            All types of your personal data are processed by the Processing
            Manager for the following purposes and on the basis of:
          </p>
          <ol>
            <li>
              Fulfillment of Legal Obligations - We process your personal data
              in accordance with applicable regulations as well as for the
              purpose of notifications and reports that we are obliged to make
              in accordance with applicable regulations, all for the purpose of
              fulfilling legal obligations;
            </li>
            <li>
              Realization of the contract - We process personal data for the
              purpose of realization of the contractand fulfillment of
              contractual obligations which are the subject of the contract (eg
              the Law on Obligations), and on the basis that processing is
              necessary for fulfillment of contractual obligations;
            </li>
            <li>
              For the purpose of contacting for the purpose of efficient
              execution of business contracts, requests for information, etc.,
              based on the consent you have given us or based on the legitimate
              interest of the Processing Manager;
            </li>
            <li>
              For the purpose of promotion - The processing manager processes
              the name and surname and e-mail address based on the consent you
              gave us by subscribing to the newsletter.
            </li>
            <li>
              For the purpose of providing adequate wellness treatment and
              insurance against possible claims for damages - the Processing
              Manager processes the health data of wellness service users based
              on the legitimate interest of the Processing Manager;
            </li>
            <li>
              For the purpose of providing adequate wellness treatment and
              insurance against possible claims for damages - the Processing
              Manager processes the health data of wellness service users based
              on the legitimate interest of the Processing Manager;
            </li>
            <li>
              For the purpose of protection of persons and property - Video
              surveillance of the premises, based on the legitimate interest of
              the Processing Manager. All your personal data is processed on the
              basis of law, contract, legitimate interest, or consent.
            </li>
          </ol>
        </div>
        <div className='privacy_container_section'>
          <h2>4. Retention period</h2>
          <p>
            We generally delete your personal data after the termination of the
            contractual relationship and after the expiration of the purpose of
            processing, and at the latest after the expiration of all legal
            obligations related to the storage of personal data and until the
            withdrawal of consent, or until we request renewal of consent.
          </p>
        </div>
        <div className='privacy_container_section'>
          <h2>5. Management of consent for the processing of personal data</h2>
          <p>
            You can change your consent (full or partial revocation) by
            contacting us by e-mail at: voditelj.obrade@manas.hr or by mail at:
            Cora hotel, Put Supavla 39, 21000 Split
          </p>
          <p>
            If you revoke the given consents, we will no longer use your data
            for the stated purposes, but this may result in the impossibility of
            using some additional benefits related to them.
          </p>
          <p>
            Withdrawal of consent does not affect the lawfulness of processing
            based on consent before it is withdrawn.
          </p>
          <p>
            If you want to give your consent again, you can do so in the same
            way as if you revoked it.
          </p>
          <p>
            If you do not provide us with your personal data, which does not
            require consent, and which is necessary for concluding a contract
            with us, fulfilling a concluded contract or due to obligations we
            have under the law, we will not be able to fulfill our contractual
            obligations to you. contract.
          </p>
        </div>
        <div className='privacy_container_section'>
          <h2>6. Rights of respondents</h2>
          <ol>
            <li>
              Right of access and information on the processing of personal
              data: You have the right to ask us to confirm whether personal
              data relating to you are processed and the purpose of processing
              if such personal data is processed, groups of your personal data
              we hold, third parties or groups of third parties the parties with
              whom your personal data is shared, the period of time during which
              we retain the dana as well as the source of your personal data
              that we did not collect directly from you
            </li>
            <li>
              Right to Correction: If we process your personal data that is
              incomplete or inaccurate, you may at any time ask us to correct or
              supplement it.
            </li>
            <li>
              Right to delete: You can ask us to delete your personal data
            </li>
            <li>
              Right to Restrict Processing - You can request a restriction on
              the processing of your data:
              <ul>
                <li>
                  If you dispute the accuracy of the data during a period that
                  allows us to verify the accuracy of that data,
                </li>
                <li>
                  If you dispute the accuracy of the data during a period that
                  allows us to verify the accuracy of that data,
                </li>
                <li>
                  if we no longer need the data for the intended purposes, but
                  you still need them to meet legal requirements, or if an
                  objection has been filed for the processing of such data.
                </li>
              </ul>
            </li>
            <li>The right to object to the processing of personal data</li>
            <li>
              The right to the possibility of data transfer - You can ask us to
              provide you with the data entrusted to us in a structured form, in
              the usual machine-readable format:
              <ul>
                <li>
                  If we process this information based on the consent you have
                  given us and which you can revoke and
                </li>
                <li>if processing is performed using automated processes.</li>
              </ul>
            </li>
            <li>
              The right to lodge a complaint with the supervisory authority: If
              you are of the opinion that we have violated Croatian or European
              data protection regulations during the processing of your data,
              please contact us to clarify any issues. You certainly have the
              right to file a complaint with the Croatian Agency for Personal
              Data Protection. Confirmation of identity: In case of doubt, we
              may request additional information to verify your identity, which
              serves to protect your rights. Abuse of rights: If you use any of
              these rights too often and with an obvious intent to abuse, we may
              charge an administrative fee or refuse to process your request.
            </li>
          </ol>
        </div>
        <div className='privacy_container_section'>
          <h2>7. Data transfer to third parties</h2>
          <p>
            We undertake to keep your personal data and will not disclose or
            make it available to third parties except in the following cases:
          </p>
          <ul>
            <li>
              If you expressly agree in writing to disclose certain confidential
              information for a specific purpose or to a specific person,
            </li>
            <li>
              if the information is necessary for the Ministry of the Interior
              or the competent state attorney's office for the purpose of
              performing tasks within their competence,
            </li>
            <li>
              if the information is required by the court, lawyers or notary
              public for the proceedings they are conducting, and the
              presentation of such information is required in writing,
            </li>
            <li>
              if this information is needed by the tax administration, the
              Pension and Health Insurance Institute, all on the basis of the
              legal obligations that the processing manager has towards them,
            </li>
            <li>
              if this information is needed by the Ministry of Finance or the
              tax authority in the procedure it conducts within its competences
              and
            </li>
            <li>
              if we have to provide data to third parties as part of a
              contractual relationship.
            </li>
          </ul>
        </div>
        <div className='privacy_container_section'>
          <h2>8. Use of digital services (Website, applications)</h2>
          <p>
            We collect only those personal data that visitors to our official
            website voluntarily make available to us when submitting requests
            for contact and business information, requests to enter into a
            business relationship, job competitions, callback services, requests
            to receive newsletters and other electronic forms. This personal
            information is used confidentially and only for the specific purpose
            stated with each form. We transfer personal data to third parties if
            there is a legal obligation or order of an official body, when these
            personal data can be transferred to the competent authority and only
            those data that are necessary for that. Access to the website is
            recorded and technical data such as page traffic, operating system
            used, screen resolution, time of visit and size of transmitted data
            are recorded.
          </p>
          <p>
            In order to improve our offer, the website contains "cookies" that
            are stored on the computer of visitors to our official website. The
            storage of "cookies" can also be prevented, but in this regard, a
            limited offer of our official website is possible. "Cookies" provide
            the ability to store the characteristic preferences of website
            visitors, optimize technical processes and continuously improve the
            offer.
          </p>
        </div>
        <div className='privacy_container_section'>
          <h2>9. Technical and organizational measures</h2>
          <p>
            We have taken all technical and organizational measures to protect
            your data from loss, alteration or access by a third party. In case
            of any questions, feel free to contact us, and we will answer your
            requests, requests, and concerns and help you exercise your rights
            as soon as possible. Any changes regarding our privacy policy will
            be published in the Privacy Statement on our official website and
            you will be adequately informed about them.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
