import ScrollAnimation from "../../../utils/scrollAnimation";

const Amenities = ({ lang }: { lang: Locale.Keys }) => {
  return (
    <div className='accommodations_container_info_details'>
      <div>
        <ScrollAnimation animateIn='fadeInUp' duration={500}>
          <p>{lang.amenities.entertainment.title}</p>
          <ul>
            <li>{lang.amenities.entertainment.tv}</li>
          </ul>
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeInUp' duration={500}>
          <p>{lang.amenities.bedFeatures.title}</p>
          <ul>
            <li>{lang.amenities.bedFeatures.sheets}</li>
            <li>{lang.amenities.bedFeatures.pillow}</li>
            <li>{lang.amenities.bedFeatures.pillowMenu}</li>
            <li>{lang.amenities.bedFeatures.service}</li>
          </ul>
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeInUp' duration={500}>
          <p>{lang.amenities.bath.title}</p>
          <ul>
            <li>{lang.amenities.bath.shower}</li>
            <li>{lang.amenities.bath.hairdryer}</li>
            <li>{lang.amenities.bath.cosmetic}</li>
            <li>{lang.amenities.bath.scale}</li>
            <li>{lang.amenities.bath.amenities}</li>
            <li>{lang.amenities.bath.heating}</li>
          </ul>
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeInUp' duration={500}>
          <p>{lang.amenities.office.title}</p>
          <ul>
            <li>{lang.amenities.office.telephone}</li>
            <li>{lang.amenities.office.wifi}</li>
            <li>{lang.amenities.office.wakeUp}</li>
            <li>{lang.amenities.office.elePlug}</li>
          </ul>
        </ScrollAnimation>
      </div>
      <div>
        <ScrollAnimation animateIn='fadeInUp' duration={500}>
          <p>{lang.amenities.dining.title}</p>
          <ul>
            <li>{lang.amenities.dining.wine}</li>
            <li>{lang.amenities.dining.minibar}</li>
            <li>{lang.amenities.dining.kettle}</li>
          </ul>
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeInUp' duration={500}>
          <p>{lang.amenities.features.title}</p>
          <ul>
            <li>{lang.amenities.features.smoke}</li>
            <li>{lang.amenities.features.ac}</li>
            <li>{lang.amenities.features.desk}</li>
            <li>{lang.amenities.features.light}</li>
            <li>{lang.amenities.features.safe}</li>
            <li>{lang.amenities.features.bathrobe}</li>
            <li>{lang.amenities.features.laundry}</li>
          </ul>
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeInUp' duration={500}>
          <p>{lang.amenities.additional.title}</p>
          <ul>
            <li>{lang.amenities.additional.beach}</li>
            <li>{lang.amenities.additional.crib}</li>
            <li>{lang.amenities.additional.gifts}</li>
            <li>{lang.amenities.additional.pets}</li>
          </ul>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default Amenities;
